/*

  s t o r e
  Store

  :description:
  A Redux Store that groups our reducers @ the state key we set within our reducer.

*/

//
//  :react & redux:
import { configureStore } from '@reduxjs/toolkit';

//
//  :reducers:
import authSlice from '../features/auth/authSlice';
import bestBuySyndicationSlice from '../features/best-buy-landing/bestBuySyndicationSlice';
import virtualRoomQuestionnaireSlice from '../features/virtual-room-questionnaire/virtualRoomQuestionnaireSlice';
import appCoreReducer from '../features/app-core/redux/appCoreSlice'

export const store = configureStore({
  reducer: {
    auth: authSlice,
    virtualRoomQuestionnaire: virtualRoomQuestionnaireSlice,
    bestBuySyndicationSlice: bestBuySyndicationSlice,
    appCore: appCoreReducer
  },
});