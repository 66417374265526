/*

  i n d e x
  Index

  :description:
  Our main file.

*/

//
//  :react & redux:
import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

//
//  :store:

//
//  :code:
import { store } from './app/store'
import reportWebVitals from './reportWebVitals'

//
//  :global css:
import './index.css'

//
//  :pages:
import { Layout } from './components/Layout'
import { Home } from './pages/Home'
import { NotFound } from './pages/NotFound'
import { StateOfTheIndustryBeveragesPage } from './pages/state-of-the-industry/Page'
import { CocaColaAvatarPage } from './pages/avatars/coca-cola/Page'
//import { VirtualRoomQuestionnaire } from './pages/virtual-room-questionnaire/Page';
import { VirtualRoomQuestionnaireV3 } from './pages/virtual-room-questionnaire/v3/Page'
import { BestBuyLandingv2 } from './pages/best-buy-landing/v2/Page'
import { ToolsEyekandyURLsPage } from './pages/tools/eyekandy-urls/Page'
import { ARShowcaseV2 } from './pages/ar-showcase/v2/Page'
import { Inpage } from './pages/inpage/Page'
import { BevviGreyGooseContent } from './pages/inpage/grey-goose/bevvi/Page'
import { IntelEvoI7 } from './pages/inpage/intel/evo-i7/Page'
import { GoPuffGreyGooseContent } from './pages/inpage/grey-goose/go-puff/Page'
import { ModelFiles } from './pages/tools/model-files/Page'
import { DoordashGreyGooseContent } from './pages/inpage/grey-goose/doordash/Page'
import { TwinLiquorsGreyGooseContent } from './pages/inpage/grey-goose/twin-liquors/Page'
import { SGProofGreyGooseContent } from './pages/inpage/grey-goose/sg-proof/Page'
import { SpecsOnlineGreyGooseContent } from './pages/inpage/grey-goose/specs-online/Page'
import { IntelInteractiveExperienceViewerPage } from './pages/tools/intel-interactive-experience-viewer/Page'
import { GoPuffAbsolutContent } from './pages/inpage/absolut/go-puff/Page'
import { GoPuffCampariContent } from './pages/inpage/campari/go-puff/Page'
import { GoPuffJimBeamContent } from './pages/inpage/jim-beam/go-puff/Page'
import { GoPuffJohnnieWalkerContent } from './pages/inpage/johnnie-walker/go-puff/Page'
import { GoPuffJackDanielsContent } from './pages/inpage/jack-daniels/go-puff/Page'
import { GoPuffJagermeisterContent } from './pages/inpage/jagermeister/go-puff/Page'
import { GoPuffMoetContent } from './pages/inpage/moet-and-chandon/go-puff/Page'
import { CrossmarkAndULViewer } from './pages/tools/crossmark-ul-viewer/Page'
import { EKNexusLaunch } from './pages/ek-nexus-launch'

//
//  :runtime:
//  Select our target div from the HTML.
const container = document.getElementById('root')

//
//  Using React, create a "React.Root" object.
const root = createRoot(container)

//
//  Note, removed encasing <React.Strict>.
//  Render our root, passing the store, and embedding our routing within our <Layout>
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="state-of-the-industry/beverages" element={<StateOfTheIndustryBeveragesPage />} />
          <Route path="avatars/coca-cola" element={<CocaColaAvatarPage />} />
          <Route path="virtual-room/questionnaire" element={<VirtualRoomQuestionnaireV3 />} />
          <Route path="best-buy/landing" element={<BestBuyLandingv2 />} />
          <Route path="tools/eyekandy-urls" element={<ToolsEyekandyURLsPage />} />
          <Route path="showcase" element={<ARShowcaseV2 />} />
          <Route path="inpage/grey-goose" element={<Inpage />} />
          <Route path="inpage/bevvi/grey-goose" element={<BevviGreyGooseContent />} />
          <Route path="inpage/go-puff/grey-goose" element={<GoPuffGreyGooseContent />} />
          <Route path="inpage/go-puff/absolut" element={<GoPuffAbsolutContent />} />
          <Route path="inpage/go-puff/campari" element={<GoPuffCampariContent />} />
          <Route path="inpage/go-puff/jim-beam" element={<GoPuffJimBeamContent />} />
          <Route path="inpage/go-puff/johhnie-walker" element={<GoPuffJohnnieWalkerContent />} />
          <Route path="inpage/go-puff/jack-daniels" element={<GoPuffJackDanielsContent />} />
          <Route path="inpage/go-puff/jagermeister" element={<GoPuffJagermeisterContent />} />
          <Route path="inpage/go-puff/moet" element={<GoPuffMoetContent />} />

          
          <Route path="inpage/doordash/grey-goose" element={<DoordashGreyGooseContent />} />
          <Route path="inpage/twin-liquors/grey-goose" element={<TwinLiquorsGreyGooseContent />} />
          <Route path="inpage/sg-proof/grey-goose" element={<SGProofGreyGooseContent />} />
          <Route path="inpage/specs-online/grey-goose" element={<SpecsOnlineGreyGooseContent />} />

          <Route path="inpage/intel/evo-i7" element={<IntelEvoI7 />} />

          <Route path="tools/model-files" element={<ModelFiles />} />

          <Route path="tools/intel-interactive-experience-viewer" element={<IntelInteractiveExperienceViewerPage />} />

          <Route path="tools/crossmark-ul-viewer" element={<CrossmarkAndULViewer />} />

          <Route path="ek-nexus/launch" element={<EKNexusLaunch />} />

        </Route>

        <Route path="*" element={<NotFound />}></Route>
      </Routes>
    </BrowserRouter>
  </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
